import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, styled, Typography, useTheme } from '@mui/material';
import { faBallotCheck, faFiles, faTriangleExclamation, faUsersClass } from '@fortawesome/pro-duotone-svg-icons'
import { useNavigate } from 'react-router-dom';
import ViewMeeting from './ViewMeeting';
import ViewDeviation from './ViewDeviation';
import ViewForms from './ViewForms';
import ViewFiles from './ViewFiles';


const CustomListItem = styled(ListItemButton)({
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 9,
    '&:hover': {
        backgroundColor: '#001E3C'
    },
    '&.Mui-selected': {
        backgroundColor: '#122F4C',
        border: '1pt solid #5090D3',
        padding: '15px',
        '&:hover': {
            backgroundColor: '#122F4C'
        },
    }
});

const MobileCustomListItem = styled(ListItem)({
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 9,
});


function ProductViewer() {

    const theme = useTheme()
    const navigate = useNavigate()
    const [selected, setSelected] = React.useState('meeting')

    const handleChange = (vale) => {
        setSelected(vale)
    }

    const goToProducts = () => {
        navigate('/product')
    }


    const getContent = () => {
        switch (selected) {
            case 'meeting':
                return <ViewMeeting />

            case 'deviation':
                return <ViewDeviation />

            case 'forms':
                return <ViewForms />

            case 'files':
                return <ViewFiles />
        
            default:
                return <ViewMeeting />
        }
    }

    return (
        <Grid container spacing={0}>
            <Grid item md={4} xs={12}>
                <Typography variant="subtitle1" color="primary" align="left">Produkt</Typography>
                <Typography variant="h4" align="left" sx={{fontWeight: 500}} gutterBottom>Alle <span style={{color: theme.palette.primary.main}}>verktøy</span> for prosjektet <span style={{color: theme.palette.primary.main}}>samlet</span> i ett system</Typography>
                <Typography variant="body2" color="text.secondary" align="left">Gjennomfør prosjektene mer effektivt med smartplan</Typography>

                <Box sx={{
                    display: {
                        md: 'flex',
                        xs: 'none'
                    }
                }}>
                    <List sx={{my: 6}}>
                        <CustomListItem
                            onClick={() => handleChange('meeting')}
                            selected={selected === 'meeting'}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{background: '#122F4C'}}>
                                    <FontAwesomeIcon icon={faUsersClass} style={{color: theme.palette.primary.main}} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography variant="subtitle2" gutterBottom sx={{fontWeight: 600}}>Møtearbeid</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Utarbeid referater fra møtet mens møtene pågår. Spar tid, og unngå misforståelser mellom partene.
                                </Typography>
                            </ListItemText>
                        </CustomListItem>
                        <CustomListItem
                            onClick={() => handleChange('deviation')}
                            selected={selected === 'deviation'}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{background: '#122F4C'}}>
                                    <FontAwesomeIcon icon={faTriangleExclamation} style={{color: theme.palette.primary.main}} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography variant="subtitle2" gutterBottom sx={{fontWeight: 600}}>Avvikshåndtering</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Registrer avvik direkte i tegninger, og la ansvarlig part håndtere egne avvik i prosjektet. 
                                </Typography>
                            </ListItemText>
                        </CustomListItem>
                        <CustomListItem
                            onClick={() => handleChange('forms')}
                            selected={selected === 'forms'}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{background: '#122F4C'}}>
                                    <FontAwesomeIcon icon={faBallotCheck} style={{color: theme.palette.primary.main}} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography variant="subtitle2" gutterBottom sx={{fontWeight: 600}}>Skjema og sjekklister</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Benytt egne utarbeidede skjemaer og sjekklister for å sikre kvaliteten i prosjektet.
                                </Typography>
                            </ListItemText>
                        </CustomListItem>
                        <CustomListItem 
                            onClick={() => handleChange('files')}
                            selected={selected === 'files'}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{background: '#122F4C'}}>
                                    <FontAwesomeIcon icon={faFiles} style={{color: theme.palette.primary.main}} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography variant="subtitle2" gutterBottom sx={{fontWeight: 600}}>Fillagring</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Fillagring tilpasset prosjektets behov og varighet. Enkel tilgang til dokumentasjonen i prosjektet.
                                </Typography>
                            </ListItemText>
                        </CustomListItem>
                    </List>
                </Box>

                <Box sx={{
                    display: {
                        md: 'none',
                        xs: 'flex'
                    }
                }}>
                    <List sx={{my: 6}}>
                        <MobileCustomListItem>
                            <ListItemAvatar>
                                <Avatar sx={{background: '#122F4C'}}>
                                    <FontAwesomeIcon icon={faUsersClass} style={{color: theme.palette.primary.main}} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography variant="subtitle2" gutterBottom sx={{fontWeight: 600}}>Møtearbeid</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Utarbeid referater fra møtet mens møtene pågår. Spar tid, og unngå misforståelser mellom partene.
                                </Typography>
                            </ListItemText>
                        </MobileCustomListItem>
                        <MobileCustomListItem>
                            <ListItemAvatar>
                                <Avatar sx={{background: '#122F4C'}}>
                                    <FontAwesomeIcon icon={faTriangleExclamation} style={{color: theme.palette.primary.main}} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography variant="subtitle2" gutterBottom sx={{fontWeight: 600}}>Avvikshåndtering</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Registrer avvik direkte i tegninger, og la ansvarlig part håndtere egne avvik i prosjektet. 
                                </Typography>
                            </ListItemText>
                        </MobileCustomListItem>
                        <MobileCustomListItem>
                            <ListItemAvatar>
                                <Avatar sx={{background: '#122F4C'}}>
                                    <FontAwesomeIcon icon={faBallotCheck} style={{color: theme.palette.primary.main}} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography variant="subtitle2" gutterBottom sx={{fontWeight: 600}}>Skjema og sjekklister</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Benytt egne utarbeidede skjemaer og sjekklister for å sikre kvaliteten i prosjektet.
                                </Typography>
                            </ListItemText>
                        </MobileCustomListItem>
                        <MobileCustomListItem>
                            <ListItemAvatar>
                                <Avatar sx={{background: '#122F4C'}}>
                                    <FontAwesomeIcon icon={faFiles} style={{color: theme.palette.primary.main}} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography variant="subtitle2" gutterBottom sx={{fontWeight: 600}}>Fillagring</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Fillagring tilpasset prosjektets behov og varighet. Enkel tilgang til dokumentasjonen i prosjektet.
                                </Typography>
                            </ListItemText>
                        </MobileCustomListItem>
                    </List>
                </Box>

                <Button 
                    onClick={goToProducts}
                    sx={{
                        width: {md: 'inherit', xs: '100%'}
                    }}
                >
                    Vis alle produkter
                </Button>

            </Grid>
            <Grid item md={2} xs={0} />
            <Grid item md={6}>
                <Box sx={{
                    display: {md: 'flex', xs: 'none'},
                    height: {md: '100%', xs: '0px'},
                    }}>
                    {getContent()}
                </Box>
            </Grid>
        </Grid>
    )
}

export default ProductViewer