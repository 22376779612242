import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import React from 'react'
import MobileCompanyPrices from './MobileCompanyPrices';
import MobileProjectPrices from './MobileProjectPrices';

function MobilePriceTable() {

    const [value, setValue] = React.useState('1')

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList variant="fullWidth" onChange={handleChange} aria-label="Mobile price table">
                    <Tab label="Prosjekt" value="1" />
                    <Tab label="Bedrift" value="2" />
                </TabList>
                </Box>
                <TabPanel value="1" sx={{p:0}}>
                    <MobileProjectPrices />
                </TabPanel>
                <TabPanel value="2" sx={{p:0}}>
                    <MobileCompanyPrices />
                </TabPanel>
            </TabContext>
        </Box>
    )
}

export default MobilePriceTable