import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import React from 'react'
import ViewMeeting from '../Home/HomeComponents/ProductViewer/ViewMeeting'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import ViewDeviation from '../Home/HomeComponents/ProductViewer/ViewDeviation'

function Deviation() {

    const theme = useTheme()
    
    return (
        <Grid container spacing={6} alignItems="center">
            <Grid item md={4} xs={12}>
                <Typography variant="h4" color="primary" gutterBottom sx={{fontWeight: 500}}>Kvalitet</Typography>
                <Typography gutterBottom>
                    Ha full oversikt over kvalitetsavvik i prosjektet.
                </Typography>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Gå befaring og registrer avvik på mobil.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Effektiv behandling av innrapporterte saker.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Statisikk for innrapporterte hendelser samlet på et sted.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Utform egne skjemaer og sjekklister for kontroll av kvalitet i prosjektet.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Bedriftens egen logo på skjemaer.</Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </Grid>
            <Grid item md={2} xs={0} />
            <Grid item md={6} xs={12}>
                <Box sx={{height: 400, minHeight: 100}}>
                    <ViewDeviation />
                </Box>
            </Grid>
        </Grid>
    )
}

export default Deviation