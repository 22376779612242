import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import React from 'react'
import ViewMeeting from '../Home/HomeComponents/ProductViewer/ViewMeeting'
import Deviation from './Deviation'
import Files from './Files'
import Forms from './Forms'
import Meetings from './Meetings'

function Product() {

    const theme = useTheme()

    return (
        <Box>
            <Box sx={{py: 20}}>

                <Container maxWidth="xl">
                    <Typography align="center" variant="h6" color="primary" gutterBottom sx={{fontWeight: 500}}>Produkt</Typography>
                    <Typography align="center" variant="h2" sx={{fontWeight: 500, maxWidth: 1100, margin: 'auto'}} gutterBottom>Alle <span style={{color: theme.palette.primary.main}}>verktøy</span> for prosjektet <span style={{color: theme.palette.primary.main}}>samlet</span> i ett system</Typography>
                    <Typography align="center" variant="body2" color="text.secondary">Gjennomfør prosjektene mer effektivt med smartplan</Typography>
                </Container>

            </Box>
            <Box sx={{background: '#0A1929', textAlign: 'left', py:10}}>
                <Container maxWidth="xl">
                    <Meetings />
                </Container>
            </Box>
            <Divider />
            <Box sx={{background: '#0A1929', textAlign: 'left', py:10}}>
                <Container maxWidth="xl">
                    <Deviation />
                </Container>
            </Box>
            <Divider />
            <Box sx={{background: '#0A1929', textAlign: 'left', py:10}}>
                <Container maxWidth="xl">
                    <Forms />
                </Container>
            </Box>
            <Divider />
            <Box sx={{background: '#0A1929', textAlign: 'left', py:10}}>
                <Container maxWidth="xl">
                    <Files />
                </Container>
            </Box>
        </Box>
    )
}

export default Product