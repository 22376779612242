import React from 'react'
import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import moment from 'moment'
import { useTheme } from '@mui/material/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDash } from '@fortawesome/pro-solid-svg-icons'

function Footer() {

    const theme = useTheme()

    return (
        <>
        <Divider />
        <Container maxWidth="xl">
            <Box sx={{my:6}}>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sx={{textAlign: 'left'}}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                mb: 4
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    fontFamily: 'all-round-gothic',
                                    fontWeight: 500,
                                    fontStyle: 'normal',
                                    mr: 0.5
                                }}
                            >
                                smartplan
                            </Typography>
                            <FontAwesomeIcon icon={faDash} style={{color: theme.palette.primary.main, marginTop: -16}} size="lg" />
                        </Box>
                        <Typography variant="subtitle1" gutterBottom>Smartplan AS</Typography>
                        <Typography variant="body2" color="text.secondary">Vestre svanholmen 3,</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>4313 Sandnes</Typography>
                        <Typography variant="body2" color="text.secondary">post@smartplan.no</Typography>
                        <Typography variant="body2" color="text.secondary">+47 52 05 52 25</Typography>
                    </Grid>
                    <Grid item md={2} xs={12} sx={{textAlign: {md: 'left'}}}>
                        {/* <Typography variant="subtitle1" gutterBottom>Produkt</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Avtaler</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Møter</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Endringer</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Økonomi</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>HMS</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Kvalitet</Typography> */}
                    </Grid>
                    <Grid item md={2} xs={12} sx={{textAlign: {md: 'left'}}}>
                        {/* <Typography variant="subtitle1" gutterBottom>Kundestøtte</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Dokumentasjon</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Ofte stilte spørsmål</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Instruksjonsvideoer</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Utvikling</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Feilrapportering</Typography> */}
                    </Grid>
                    <Grid item md={2} xs={12} sx={{textAlign: {md: 'left'}}}>
                        {/* <Typography variant="subtitle1" gutterBottom>Bedrift</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Om oss</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Visjon</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Karriere</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Personvern</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>Kontakt</Typography> */}
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', my:3}}>
                <Typography variant="caption" color="text.secondary">Copyright © {moment().format('YYYY')} Smartplan AS</Typography>
            </Box>
        </Container>
        </>
    )
}

export default Footer