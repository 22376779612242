import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { faDash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Box, Divider, Typography, useTheme } from '@mui/material'

function CompanyPriceCategory(props) {

    const { category, items } = props
    const theme = useTheme()

    return (
        <>
        <Box
            sx={{
                background: '#0A1929',
                textAlign: 'left',
                borderRadius: 9,
                px: 2,
                py: 1,
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Avatar 
                sx={{
                    height: 30, 
                    width: 30, 
                    background: '#122F4C', 
                    mr: 2
                }}
            >
                <FontAwesomeIcon 
                    icon={category?.icon} 
                    size="xs" 
                    style={{
                        color: theme.palette.primary.main
                    }} 
                />
            </Avatar>
            <Typography variant="subtitle2">
                {category?.name}
            </Typography>
        </Box>

        {items.map((i, index) => (
            <Box key={i.id}>
                <Box 
                    sx={{display: 'flex', alignItems: 'center'}}
                >
                    <Box 
                        sx={{width: '28%', flexShrink: 0, textAlign: 'left', px:3}}
                    >
                        <Typography variant="body2" color="text.secondary">{i?.name}</Typography>
                    </Box>

                    <Box sx={{width: '36%', flexShrink: 0, p:3, textAlign: 'center'}}>
                        {typeof(i?.free) === 'string' && (<Typography variant="subtitle1">1 GB</Typography>)}
                        {typeof(i?.free) !== 'string' && i?.free && (<FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />)}
                        {typeof(i?.free) !== 'string' && !i?.free && (<FontAwesomeIcon icon={faDash} style={{color: theme.palette.text.secondary}} />)}
                    </Box>

                    <Box sx={{width: '36%', flexShrink: 0, p:3, textAlign: 'center'}}>
                        {typeof(i?.complete) === 'string' && (<Typography variant="subtitle1">1 GB</Typography>)}
                        {typeof(i?.complete) !== 'string' && i?.complete && (<FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />)}
                        {typeof(i?.complete) !== 'string' && !i?.complete && (<FontAwesomeIcon icon={faDash} style={{color: theme.palette.text.secondary}} />)}
                    </Box>

                </Box>
                {index !== items?.length - 1 && (
                    <Divider />
                )}
            </Box>
        ))}
        </>
    )
}

export default CompanyPriceCategory