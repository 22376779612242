import { Avatar, Box, Button, Container, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, styled, Tooltip, Typography, useTheme } from '@mui/material'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRepeat, faLayerGroup, faPeopleGroup, faSitemap } from '@fortawesome/pro-duotone-svg-icons'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, ComposedChart, Area, Line, Label } from 'recharts';
// import FrontImage from '../../assets/close-up-businessman-with-digital-tablet_full.jpg'
import ProductViewer from './HomeComponents/ProductViewer/ProductViewer';
import HomeVideo from './HomeComponents/HomeVideo';

const data = [
  { name: 'Smartplan', value: 115 },
  { name: 'Merarbeid', value: 68.75 },
//   { name: 'Group C', value: 100 },
];
// const COLORS = ['#B1DF89', '#ff7300'];
const COLORS = ['#A6CEE3', '#1F78B4', '#B2DF8A'];


const data2 = [
    {
      name: 'Endringsarbeid',
      pv: 25,
      uv: 17.5,
    },
    {
      name: 'Fremdriftsoppfølging',
      pv: 20,
      uv: 10,
    },
    {
      name: 'Møtearbeid',
      pv: 15,
      uv: 9.75,
    },
    {
      name: 'Økonomiarbeid',
      pv: 20,
      uv: 10,
    },
    {
      name: 'Avvikshåndtering',
      pv: 15,
      uv: 10.5,
    },
    {
      name: 'Samordning',
      pv: 10,
      uv: 6,
    },
    {
      name: 'Avklaringer',
      pv: 10,
      uv: 5,
    },
  ];


const CustomLabel = ({ viewBox }) => {
    const { cx, cy } = viewBox;

    return (
        <React.Fragment>
            <text x={cx} y={cy + 5} textAnchor="middle">
                <tspan
                    style={{
                    fontSize: "3em",
                      fill: "#A6CEE3",
                    }}
                >
                    37%
                </tspan>
            </text>
            <text x={cx} y={cy + 35} textAnchor="middle">
              <tspan
                style={{
                  fontSize: "0.9em",
                  fill: "#A6CEE3",
                }}
              >
                økt effektivitet
              </tspan>
            </text>
        </React.Fragment>
    );
};


function Home() {

    const theme = useTheme()

    return (
        <Box>
            <Container maxWidth="xl">
                <Box sx={{my: { md: 20, xs: 5}}}>
                    <Grid container spacing={8}>
                        <Grid item md={6} xs={12} order={{ xs: 2, md: 1 }}>
                            <Box sx={{maxWidth: 600}}>
                                <Typography variant="h2" align="left" gutterBottom sx={{fontWeight: 700}}>Et <span style={{color: theme.palette.primary.main}}>smartere</span> verktøy for morgendagens prosjekter</Typography>
                                <Typography align="left" variant="body2" color="text.secondary">
                                    Smartplan tilbyr et digitalt verktøy for alle faser i bygg- og anleggsprosjekter. Benytt Smartplan i bedriftens prosjekter å opplev en enklere, smartere og mer effektiv prosjektstyring.
                                </Typography>

                                <Box sx={{textAlign: 'left', my: 6}}>
                                    <Button 
                                        variant="contained" 
                                        size="large" 
                                        sx={{mr:2}}
                                        component={'a'} 
                                        href="https://app.smartplan.no/auth/register"
                                    >
                                        Prøv gratis
                                    </Button>
                                    <Button 
                                        variant="outlined" 
                                        size="large"
                                        component={'a'} 
                                        href="https://app.smartplan.no"
                                    >
                                        Logg inn
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12} order={{ xs: 1, md: 2 }}>
                            <HomeVideo />
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Box sx={{background: '#0A1929', py:{ md: 20, xs: 8}}}>
                <Container maxWidth="xl">
                    <ProductViewer />
                </Container>
            </Box>

            <Container maxWidth="xl">
                <Box sx={{my:{ md: 10, xs: 5}}}>
                    <Grid container spacing={2}>
                        <Grid item md={3} xs={12}>
                            <Paper variant="outlined" sx={{p:2, background: '#122F4C', borderColor: '#1D4976', display: 'flex'}}>
                                <Avatar sx={{background: '#15395A'}}>
                                    <FontAwesomeIcon icon={faArrowsRepeat} size="lg" style={{color: theme.palette.primary.main}} />
                                </Avatar>
                                <Box sx={{ml:2}}>
                                    <Typography variant="subtitle1" align="left" gutterBottom>Prosjektstyring i sanntid</Typography>
                                    <Typography color="text.secondary" align="left">Alle prosjektdeltakerne har tilgang til informasjon så fort den er tilgjengelig.</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Paper variant="outlined" sx={{p:2, background: '#122F4C', borderColor: '#1D4976', display: 'flex'}}>
                                <Avatar sx={{background: '#15395A'}}>
                                    <FontAwesomeIcon icon={faLayerGroup} size="lg" style={{color: theme.palette.primary.main}} />
                                </Avatar>
                                <Box sx={{ml:2}}>
                                    <Typography variant="subtitle1" align="left" gutterBottom>Alt samlet på ett sted</Typography>
                                    <Typography color="text.secondary" align="left">Med Smartplan er det ikke lenger behov for flere verktøy fra ulike leverandører. Et prosjekt - ett verktøy</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Paper variant="outlined" sx={{p:2, background: '#122F4C', borderColor: '#1D4976', display: 'flex'}}>
                                <Avatar sx={{background: '#15395A'}}>
                                    <FontAwesomeIcon icon={faPeopleGroup} size="lg" style={{color: theme.palette.primary.main}} />
                                </Avatar>
                                <Box sx={{ml:2}}>
                                    <Typography variant="subtitle1" align="left" gutterBottom>Ubegrenset med brukere</Typography>
                                    <Typography color="text.secondary" align="left">En pris for alle deltakere i din bedrift. Gjør det enkelt å gi tilgang til alle som deltar i prosjektet.</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Paper variant="outlined" sx={{p:2, background: '#122F4C', borderColor: '#1D4976', display: 'flex'}}>
                                <Avatar sx={{background: '#15395A'}}>
                                    <FontAwesomeIcon icon={faSitemap} size="lg" style={{color: theme.palette.primary.main}} />
                                </Avatar>
                                <Box sx={{ml:2}}>
                                    <Typography variant="subtitle1" align="left" gutterBottom>Samle  alle bedriftens prosjekter</Typography>
                                    <Typography color="text.secondary" align="left">Smartplan kan benyttes til prosjekter i alle størrelser. Samle alle bedriften prosjekter i et system.</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Box sx={{background: '#15395B', py: { md: 10, xs: 5}}}>
                <Container maxWidth="xl">
                    <Grid container spacing={{ md: 10, xs: 6}}>
                        <Grid item md={6} xs={12}>
                            <Typography variant="h4" align="left" sx={{fontWeight: 500}} gutterBottom>Spar tid og penger med mer <span style={{color: theme.palette.primary.main}}>effektiv</span> prosjektstyring</Typography>
                            {/* <Typography variant="body2" color="text.secondary" align="left" gutterBottom>Kommunikasjon via telefon, SMS og e-post er i kombinasjon med tradisjonelle tekstbaserte verktøy utbredt arbeidsmetodikk i bygg- og anleggsprosjekter. Dette fører ofte til merarbeid som følge av uklarheter og mangelfull oppfølging og samhandling mellom aktørene i prosjektet. Gevinsten av å flytte kommunikasjonsflyten inn i et digitalt økosystem for alle aktiviteter i prosjektet kan være enorm.</Typography> */}
                            <Typography variant="body2" color="text.secondary" align="left" gutterBottom>Reduser tidsforbruket på administrative oppgaver med inntil 37% ved å benytte et smart prosjektstyringsverktøy i bedriftens prosjekter.</Typography>

                            <Box sx={{mt:10}}>
                                <ResponsiveContainer width="100%" height={300}>
                                <ComposedChart
                                    layout="vertical"
                                    data={data2}
                                    margin={{
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0,
                                    }}
                                    >
                                    {/* <CartesianGrid stroke="#f5f5f5" /> */}
                                    <XAxis type="number" hide />
                                    <YAxis dataKey="name" type="category" width={130} tick={{ fill: '#fff', fontSize: 12 }} />
                                    {/* <Tooltip /> */}
                                    {/* <Legend /> */}
                                    {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                                    <Bar 
                                        dataKey="pv" 
                                        barSize={10} 
                                        fill="#A6CEE3" 
                                        // fill="#B1DF89" 
                                        stackId="a"
                                        />
                                    <Bar 
                                        dataKey="uv" 
                                        barSize={10} 
                                        fill="#1F78B4" 
                                        stackId="a"
                                    />
                                    {/* const COLORS = ['#1F78B4', '#A6CEE3', '#B2DF8A']; */}
                                    {/* <Line dataKey="uv" stroke="#ff7300" /> */}
                                    </ComposedChart>
                                </ResponsiveContainer>

                            </Box>

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <ResponsiveContainer width="100%" height={300}>
                                <PieChart>
                                    <Pie
                                        data={data}
                                        // cx={120}
                                        // cy={200}
                                        innerRadius={105}
                                        outerRadius={120}
                                        // fill="#8884d8"
                                        paddingAngle={5}
                                        dataKey="value"
                                        stroke='none'
                                    >
                                        {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                        <Label 
                                            content={<CustomLabel />} 
                                            position="center"
                                        />
                                    </Pie>
                                    
                                </PieChart>
                            </ResponsiveContainer>

                            <Box sx={{mt: 10}}>
                                <Typography variant="body2" color="text.secondary" align="left" gutterBottom>Kommunikasjon via telefon, SMS og e-post er i kombinasjon med tradisjonelle tekstbaserte verktøy utbredt arbeidsmetodikk i bygg- og anleggsprosjekter. Dette fører ofte til merarbeid som følge av uklarheter og mangelfull oppfølging og samhandling mellom aktørene i prosjektet. Gevinsten av å flytte kommunikasjonsflyten inn i et digitalt økosystem for alle aktiviteter i prosjektet kan være enorm.</Typography>
                                <Typography variant="caption" component="div" color="text.secondary" align="left">*Tallene er hentet fra bedrifter med relevant erfaring fra bransjen</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}

export default Home