import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import React from 'react'
import ViewMeeting from '../Home/HomeComponents/ProductViewer/ViewMeeting'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'

function Meetings() {

    const theme = useTheme()
    
    return (
        <Grid container spacing={6} alignItems="center">
            <Grid item md={4} xs={12}>
                <Typography variant="h4" color="primary" gutterBottom sx={{fontWeight: 500}}>Møter</Typography>
                <Typography gutterBottom>
                    Møter er en sentral del av alle prosjekter. Med Smartplan møte, har du alltid oversikt over siste hendelser i prosjektet, og sikrer effektive og tidsbesparende møteavholdelser i dine prosjekter.
                </Typography>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Benytt egendefinerte møtemaler, eller en av de integrerte malene.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Tildel ansvar for oppgaver i prosjektet.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Oppdateringer i sanntid, for hendelser i prosjektet.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Ett-klikk utsending av referat etter avholdt møte.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Full historikk fra utvikling i møtene.</Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </Grid>
            <Grid item md={2} xs={0} />
            <Grid item md={6} xs={12}>
                <Box sx={{height: 400, minHeight: 100}}>
                    <ViewMeeting />
                </Box>
            </Grid>
        </Grid>
    )
}

export default Meetings