import { faEnvelope, faPhone } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Container, Grid, Link, Typography, useTheme } from '@mui/material'
import React from 'react'
import SimpleMapDark from '../../../components/Maps/SimpleMapDark'

function Contact() {

    const theme = useTheme()

    return (
        <Box>
            <Box sx={{py: 10}}>
                <Container maxWidth="xl">
                    <Typography align="center" variant="h6" color="primary" gutterBottom sx={{fontWeight: 500}}>Kontakt oss</Typography>
                    <Typography align="center" variant="h2" sx={{fontWeight: 500}} gutterBottom>La <span style={{color: theme.palette.primary.main}}>oss</span> ta en prat</Typography>
                    <Typography align="center" variant="body2" color="text.secondary">Vi tar også gjerne en kaffe</Typography>
                </Container>
            </Box>
            <Grid container spacing={0}>
                <Grid item md={6} xs={12}>
                    <Box sx={{height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Box sx={{textAlign: 'left'}}>
                            <Typography variant="h3" gutterBottom>Smartplan AS</Typography>
                            <Typography variant="body1" color="text.secondary">Vestre svanholmen 3</Typography>
                            <Typography variant="body1" color="text.secondary">4313 Sandnes</Typography>

                            <Box sx={{display: 'flex', alignItems: 'center', my:2}}>
                                <FontAwesomeIcon icon={faEnvelope} style={{color: theme.palette.primary.main}} />
                                <Link 
                                    href="mailto:post@smartplan.no"
                                    variant="h6" 
                                    sx={{ml:2}} 
                                >
                                    post@smartplan.no
                                </Link>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <FontAwesomeIcon icon={faPhone} style={{color: theme.palette.primary.main}} />
                                <Link 
                                    href="tel:+47 52 05 52 25"
                                    variant="h6" 
                                    sx={{ml:2}} 
                                >
                                    +47 52 05 52 25
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={{height: 700}}>
                        <SimpleMapDark />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Contact