import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import React from 'react'
import ViewMeeting from '../Home/HomeComponents/ProductViewer/ViewMeeting'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import ViewDeviation from '../Home/HomeComponents/ProductViewer/ViewDeviation'
import ViewForms from '../Home/HomeComponents/ProductViewer/ViewForms'

function Forms() {

    const theme = useTheme()
    
    return (
        <Grid container spacing={6} alignItems="center">
            <Grid item md={4} xs={12}>
                <Typography variant="h4" color="primary" gutterBottom sx={{fontWeight: 500}}>Skjema og sjekklister</Typography>
                <Typography gutterBottom>
                Skjemaer og sjekklister for utfylling og rapportering i prosjektet.
                </Typography>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Skreddersy skjemaer og sjekklister etter behov.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Velg skjemaene som skal benyttes i prosjektet.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Benytt skjemaene på tvers av modulene.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Oversiktlig statistikk for utfylte skjemaer.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Eksport av skjemaer og sjekklister til pdf.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Benytt bedriftens egen logo.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Del skjemaer med andre.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Lagre skjemer som mal for bruk i andre prosjekt, eller i bedriften.</Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </Grid>
            <Grid item md={2} xs={0} />
            <Grid item md={6} xs={12}>
                <Box sx={{height: 400, minHeight: 100}}>
                    <ViewForms />
                </Box>
            </Grid>
        </Grid>
    )
}

export default Forms