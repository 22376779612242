import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography, useTheme } from '@mui/material';
import React from 'react'

import MenuIcon from '@mui/icons-material/Menu';
import Applogo from '../AppLogo/AppLogo';
import { useNavigate } from 'react-router-dom';

// const pages = ['Produkter', 'Priser', 'Kontakt oss'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Navbar() {

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const theme = useTheme()
    const navigate = useNavigate()

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleClickMenuItem = (val) => {
        navigate(`/${val}`)
        handleCloseNavMenu()
    }

    return (
        <AppBar elevation={0} position="static" sx={{background: theme.palette.background.default}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters >
                    <Applogo sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <MenuItem onClick={() => handleClickMenuItem('product')}>
                                <Typography textAlign="center">Produkt</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => handleClickMenuItem('prices')}>
                                <Typography textAlign="center">Priser</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => handleClickMenuItem('contact')}>
                                <Typography textAlign="center">Kontakt oss</Typography>
                            </MenuItem>
                           
                        </Menu>
                    </Box>

                    <Applogo sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

                        <Button
                            onClick={() => handleClickMenuItem('product')}
                            sx={{ mx: 1, color: 'white', display: 'block' }}
                        >
                            Produkt
                        </Button>
                        <Button
                            onClick={() => handleClickMenuItem('prices')}
                            sx={{ mx: 1, color: 'white', display: 'block' }}
                        >
                            Priser
                        </Button>
                        <Button
                            onClick={() => handleClickMenuItem('contact')}
                            sx={{ mx: 1, color: 'white', display: 'block' }}
                        >
                            Kontakt oss
                        </Button>

                    </Box>

                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Button 
                            variant="outlined" 
                            sx={{mx:1}}
                            component={'a'} 
                            href="https://app.smartplan.no"
                        >
                            Logg inn
                        </Button>
                        <Button 
                            variant="contained" 
                            disableElevation
                            component={'a'} href="https://app.smartplan.no/auth/register"
                        >
                            Prøv gratis
                        </Button>
                    </Box>


                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Navbar