import React from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import BasicTile from '../ProjectPrices/Tiles/BasicTile';
import FreeTile from '../ProjectPrices/Tiles/FreeTile';
import PlusTile from '../ProjectPrices/Tiles/PlusTile';
import CompleteTile from '../ProjectPrices/Tiles/CompleteTile';
import { PROJECTPRICES } from '../ProjectPrices/projectPriceConfig'
import MobilePriceCategory from './MobilePriceCategory';

function MobileProjectPrices() {

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', background: '#0A1929' }}>
                    <TabList variant="fullWidth" onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Gratis" value="1" />
                        <Tab label="Basis" value="2" />
                        <Tab label="Plus" value="3" />
                        <Tab label="Komplett" value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <FreeTile width="100%" />
                    {PROJECTPRICES.map(p => (
                        <MobilePriceCategory key={p.id} category={p} items={p.data} type="free" />
                    ))}
                </TabPanel>
                <TabPanel value="2">
                    <BasicTile width="100%" />
                    {PROJECTPRICES.map(p => (
                        <MobilePriceCategory key={p.id} category={p} items={p.data} type="basic" />
                    ))}
                </TabPanel>
                <TabPanel value="3">
                    <PlusTile width="100%" featured={false} />
                    {PROJECTPRICES.map(p => (
                        <MobilePriceCategory key={p.id} category={p} items={p.data} type="plus" />
                    ))}
                </TabPanel>
                <TabPanel value="4">
                    <CompleteTile width="100%" />
                    {PROJECTPRICES.map(p => (
                        <MobilePriceCategory key={p.id} category={p} items={p.data} type="complete" />
                    ))}
                </TabPanel>
            </TabContext>
        </Box>  
    )
}

export default MobileProjectPrices