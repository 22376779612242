import { faBadgeCheck, faBallotCheck, faChartMixed, faDiagramProject, faExchange, faFiles, faFileSignature, faListCheck, faScreenUsers, faUserHelmetSafety } from '@fortawesome/pro-duotone-svg-icons'
import { v4 as uuid } from 'uuid'


export const PROJECTPRICES = [
    {
        id: uuid(),
        name: 'Oppgaver',
        icon: faListCheck,
        data: [
            {
                id: uuid(),
                name: 'Utføre oppgaver tildelt ansatte',
                free: true,
                basic: true,
                plus: true,
                complete: true
            },
            {
                id: uuid(),
                name: 'Tildele oppgaver til andre deltakere',
                free: true,
                basic: true,
                plus: true,
                complete: true
            },
        ]
    },
    {
        id: uuid(),
        name: 'HMS / SHA',
        icon: faUserHelmetSafety,
        data: [
            {
                id: uuid(),
                name: 'Rapportere uønskede hendelser',
                free: false,
                basic: true,
                plus: true,
                complete: true
            },
            {
                id: uuid(),
                name: 'Utføre sikker jobb analyser',
                free: false,
                basic: true,
                plus: true,
                complete: true
            },
            {
                id: uuid(),
                name: 'Gå befaringer',
                free: false,
                basic: true,
                plus: true,
                complete: true
            },
            {
                id: uuid(),
                name: 'Opplasting av HMS/SHA dokumenter',
                free: false,
                basic: true,
                plus: true,
                complete: true
            },
        ]
    },
    {
        id: uuid(),
        name: 'Kvalitet',
        icon: faBadgeCheck,
        data: [
            {
                id: uuid(),
                name: 'Rapportere kvalitetsavvik',
                free: false,
                basic: true,
                plus: true,
                complete: true
            },
            {
                id: uuid(),
                name: 'Gå befaringer',
                free: false,
                basic: true,
                plus: true,
                complete: true
            },
        ]
    },
    {
        id: uuid(),
        name: 'Avtaler',
        icon: faFileSignature,
        data: [
            {
                id: uuid(),
                name: 'Behandle og signere mottatte avtaleutkast',
                free: true,
                basic: true,
                plus: true,
                complete: true
            },
            {
                id: uuid(),
                name: 'Opprette og utarbeide avtaler',
                free: false,
                basic: false,
                plus: true,
                complete: true
            },
        ]
    },
    {
        id: uuid(),
        name: 'Møter',
        icon: faScreenUsers,
        data: [
            {
                id: uuid(),
                name: 'Delta i møter ved invitasjon',
                free: true,
                basic: true,
                plus: true,
                complete: true
            },
            {
                id: uuid(),
                name: 'Opprette og avtale møter',
                free: false,
                basic: false,
                plus: true,
                complete: true
            },
        ]
    },
    {
        id: uuid(),
        name: 'Skjemaer og sjekklister',
        icon: faBallotCheck,
        data: [
            {
                id: uuid(),
                name: 'Utfylling av skjemaer i prosjektet',
                free: true,
                basic: true,
                plus: true,
                complete: true
            },
            {
                id: uuid(),
                name: 'Administrering av skjemaer og sjekklister',
                free: false,
                basic: false,
                plus: true,
                complete: true
            },
        ]
    },
    {
        id: uuid(),
        name: 'Fillagring i prosjekt',
        icon: faFiles,
        data: [
            {
                id: uuid(),
                name: 'Lagringskapasitet',
                free: 'Tilgang til systemfiler',
                basic: '1GB',
                plus: '10GB',
                complete: '100GB'
            }
        ]
    },
    {
        id: uuid(),
        name: 'Endringer',
        icon: faExchange,
        data: [
            {
                id: uuid(),
                name: 'Behandle mottatte endringer',
                free: true,
                basic: true,
                plus: true,
                complete: true
            },
            {
                id: uuid(),
                name: 'Registrere mottatte endringer fra annet system',
                free: false,
                basic: false,
                plus: false,
                complete: true
            },
            {
                id: uuid(),
                name: 'Utarbeide og sende endringer til motpart',
                free: false,
                basic: false,
                plus: false,
                complete: true
            },
            {
                id: uuid(),
                name: 'Importere endringer fra annet system',
                free: false,
                basic: false,
                plus: false,
                complete: true
            },
        ]
    },
    {
        id: uuid(),
        name: 'Økonomi',
        icon: faChartMixed,
        data: [
            {
                id: uuid(),
                name: 'Opprette ubegrenset antall prosjektregnskap',
                free: false,
                basic: false,
                plus: false,
                complete: true
            },
            {
                id: uuid(),
                name: 'Budsjett',
                free: false,
                basic: false,
                plus: false,
                complete: true
            },
            {
                id: uuid(),
                name: 'Leverandørregnskap',
                free: false,
                basic: false,
                plus: false,
                complete: true
            },
            {
                id: uuid(),
                name: 'Fakturaregistrer',
                free: false,
                basic: false,
                plus: false,
                complete: true
            },
            {
                id: uuid(),
                name: 'Kostnadskontoer',
                free: false,
                basic: false,
                plus: false,
                complete: true
            },
        ]
    },
]

