import { Box, Typography } from '@mui/material'
import React from 'react'
import CompanyPriceCategory from './CompanyPriceCategory'
import CompanyCompleteTile from './Tiles/CompanyCompleteTile'
import CompanyFreeTile from './Tiles/CompanyFreeTile'
import { COMPANYPRICES } from './companyPriceConfig'

function CompanyPriceTable() {
    return (
        <Box sx={{pb:20}}>
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <Box sx={{width: '28%', flexShrink: 0}}>
                    <Typography variant="h5" align="left" sx={{fontWeight: 500}}>Bedrift</Typography>
                </Box>
                <CompanyFreeTile />
                <CompanyCompleteTile />
            </Box>

            {COMPANYPRICES.map(c => (
                <CompanyPriceCategory key={c.id} category={c} items={c.data} />
            ))}
            
        </Box>
    )
}

export default CompanyPriceTable