import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'
import CompanyPriceTable from './CompanyPrices/CompanyPriceTable'
import MobilePriceTable from './MobilePriceTable/MobilePriceTable'
import ProjectPriceTable from './ProjectPrices/ProjectPriceTable'

function Prices() {

    const theme = useTheme()

    return (
        <Box>
            <Box sx={{py: 20}}>
                <Container maxWidth="xl">
                    <Typography align="center" variant="h6" color="primary" gutterBottom sx={{fontWeight: 500}}>Priser</Typography>
                    <Typography align="center" variant="h2" sx={{fontWeight: 500}} gutterBottom>Prøv <span style={{color: theme.palette.primary.main}}>Smartplan</span> gratis!</Typography>
                    <Typography align="center" variant="body2" color="text.secondary">Oppgrader abonnement når behovet endres</Typography>
                </Container>
            </Box>
            
            <Box sx={{ display: {md: 'flex', xs: 'none'} }}>
                <Container maxWidth="xl">
                    <ProjectPriceTable />
                    <CompanyPriceTable />
                </Container>
            </Box>
            <Box sx={{ display: {md: 'none', xs: 'flex'} }}>
                <MobilePriceTable />
            </Box>

        </Box>
    )
}

export default Prices