import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import BasicTile from './Tiles/BasicTile'
import FreeTile from './Tiles/FreeTile'
import PlusTile from './Tiles/PlusTile'
import CompleteTile from './Tiles/CompleteTile'
import ProjectPriceCategory from './ProjectPriceCategory'
import { PROJECTPRICES } from './projectPriceConfig'

function ProjectPriceTable() {

    return (
        <Box sx={{pb:20}}>
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <Box sx={{width: '28%', flexShrink: 0}}>
                    <Typography variant="h5" align="left" sx={{fontWeight: 500}}>Prosjekt</Typography>
                </Box>
                <FreeTile />
                <BasicTile />
                <PlusTile />
                <CompleteTile />
            </Box>
            
            {PROJECTPRICES.map(c => (
                <ProjectPriceCategory key={c.id} category={c} items={c.data} />
            ))}
            <Divider />
            
        </Box>
    )
}

export default ProjectPriceTable