import { Box } from '@mui/material'
import React from 'react'
import Demoimage from '../../../../assets/demo2.png'

function ViewDeviation() {
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center'
            }}>
            <img 
                src={Demoimage} 
                alt="Avvik" 
                style={{width: '100%'}}
            />
        </Box>
    )
}

export default ViewDeviation