import './App.css';
import { lightBlue } from '@mui/material/colors';
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import "moment/locale/nb";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import Navbar from './components/layout/Navbar/Navbar';
import Footer from './components/layout/Footer/Footer';
import Home from './views/Home/Home';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Product from './views/Product/Product';
import Prices from './views/Home/Prices/Prices';
import Contact from './views/Home/Contact/Contact';

function App() {

    let theme = createTheme({
        palette: {
            mode: "dark",
            primary: {
                // main: lightBlue[400],
                main: '#66B2FD',
            },
            // secondary: {
            //     main: '#fff',
            //     light: '#f2f2f2',
            //     dark: '#d9d9d9',
            //     contrastText: '#757575'
            // },
            background: {
                // default: '#0A2143'
                default: '#001E3C'
            },
          
        },
        typography: {
            subtitle1: {
                fontWeight: 600
            },
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                'Montserrat',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        components: {
            MuiPaper: {
                styleOverrides: {
                    rounded: {
                        borderRadius: 9,
                        backgroundColor: '#0A1929',
                    },
                },
            },
        }
    });

    theme = responsiveFontSizes(theme);

    return (
        <div className="App">
        <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <CssBaseline/>
                    <Router>
                        <Navbar />
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/product" element={<Product />} />
                            <Route path="/prices" element={<Prices />} />
                            <Route path="/contact" element={<Contact />} />
                        </Routes>
                        <Footer />
                    </Router>
                </LocalizationProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
