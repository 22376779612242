import React from 'react'
import { Box, ButtonBase, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDash } from '@fortawesome/pro-solid-svg-icons'


function AppLogo(props) {

    const { sx } = props

    const theme = useTheme()
    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/')
    }

    return (
        <Box sx={{...sx}}>
            <ButtonBase
                focusRipple
                onClick={handleClick}
                sx={{
                    padding: '6px 6px 0px',
                    borderRadius: 2,
                    // '&:hover': {
                    //     background: '#F6F7F8'
                    // },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}
            >
                
                <Typography
                    variant="h4"
                    sx={{
                        fontFamily: 'all-round-gothic',
                        fontWeight: 500,
                        fontStyle: 'normal',
                        mr: 0.5
                    }}
                >
                    smartplan
                </Typography>
                <FontAwesomeIcon icon={faDash} style={{color: theme.palette.primary.main, marginTop: -16}} size="2x" />
            </ButtonBase>
        </Box>

    )
}
export default AppLogo