import React from "react";
import { Box } from "@mui/material";
import Vid from "../../../assets/Showcase_3.mp4";

function HomeVideo() {

    return (
        <Box 
            sx={{
                width: '100%',
                position: 'relative'
                // background: 'rgb(0,30,60)',
            }}
        >
            <video 
                autoPlay 
                muted 
                playsInline 
                loop
                style={{width: 'inherit', height: 'inherit'}}
            >
                <source src={Vid} type="video/mp4" />
            </video>
            <Box sx={{
                background: 'linear-gradient(90deg, rgba(0,30,60,1) 0%, rgba(0,30,60,0) 20%, rgba(0,30,60,0) 80%, rgba(0,30,60,1) 100%)',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }} />
            <Box sx={{
                background: 'linear-gradient(0deg, rgba(0,30,60,1) 0%, rgba(0,30,60,0) 10%, rgba(0,30,60,0) 90%, rgba(0,30,60,1) 100%)',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }} />
            
        </Box>
    )
}

export default HomeVideo