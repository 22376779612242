import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import React from 'react'
import { COMPANYPRICES } from '../CompanyPrices/companyPriceConfig';
import CompanyCompleteTile from '../CompanyPrices/Tiles/CompanyCompleteTile';
import CompanyFreeTile from '../CompanyPrices/Tiles/CompanyFreeTile';
import MobilePriceCategory from './MobilePriceCategory';

function MobileCompanyPrices() {

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    
  return (
    <Box sx={{ width: '100%' }}>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', background: '#0A1929' }}>
            <TabList variant="fullWidth" onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Gratis" value="1" />
                <Tab label="Komplett" value="2" />
            </TabList>
            </Box>
            <TabPanel value="1">
                <CompanyFreeTile width="100%" />
                {COMPANYPRICES.map(p => (
                    <MobilePriceCategory key={p.id} category={p} items={p.data} type="free" />
                ))}
            </TabPanel>
            <TabPanel value="2">
                <CompanyCompleteTile width="100%" />
                {COMPANYPRICES.map(p => (
                    <MobilePriceCategory key={p.id} category={p} items={p.data} type="complete" />
                ))}
            </TabPanel>
        </TabContext>
    </Box>
  )
}

export default MobileCompanyPrices