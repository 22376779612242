import { faDiagramProject, faScreenUsers } from '@fortawesome/pro-duotone-svg-icons'
import { v4 as uuid } from 'uuid'


export const COMPANYPRICES = [
    {
        id: uuid(),
        name: 'Prosjekter',
        icon: faDiagramProject,
        data: [{
            id: uuid(),
            name: 'Oversikt over bedriftens prosjekter',
            free: true,
            complete: true
        }]
    },
    {
        id: uuid(),
        name: 'Møter',
        icon: faScreenUsers,
        data: [
            {
                id: uuid(),
                name: 'Tilgang til bedriftens møtereferater',
                free: true,
                complete: true
            },
            {
                id: uuid(),
                name: 'Opprette og avholde interne møter i bedriften',
                free: false,
                complete: true
            },
            {
                id: uuid(),
                name: 'Utforme møtemaler for bruk både internt og i prosjekter',
                free: false,
                complete: true
            },
        ]
    },
    {
        id: uuid(),
        name: 'Maler',
        icon: faScreenUsers,
        data: [
            {
                id: uuid(),
                name: 'Utarbeide maler for kontrakter og avtaler, for bruk i alle bedriftens prosjekter',
                free: false,
                complete: true
            },
            {
                id: uuid(),
                name: 'Utarbeide faseplaner for prosjektgjennomføring, for bruk i alle bedriftens prosjekter',
                free: false,
                complete: true
            },
            {
                id: uuid(),
                name: 'Utarbeide møtemaler, for bruk i internmøter og alle bedriftens prosjekter',
                free: false,
                complete: true
            },
            {
                id: uuid(),
                name: 'Utarbeide skjemaer og sjekklister, for bruk i alle bedriftens prosjekter. Tilgjengelig både på web og mobil app.',
                free: false,
                complete: true
            },
        ]
    },
]

