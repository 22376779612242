import { Box, Button, Typography, useTheme } from '@mui/material'
import React from 'react'

function BasicTile(props) {

    const { width = '18%' } = props
    const theme = useTheme()

    return (
        <Box 
            sx={{
                height: 282,
                width: width,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                p:2,
                textAlign: 'left'
            }}
        >
            <Typography variant="subtitle1" gutterBottom>Basis</Typography>
            <Typography variant="body2" color="text.secondary">Prosjektarbeid som krever de mest grunnleggende funksjonene. Oppgrader når behovet endres.</Typography>
            <Box 
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="h4" color="primary" sx={{mr:1, fontWeight: 500}}>kr 699,-</Typography>
                <Typography variant="body2" color="text.secondary">pr mnd / pr prosjekt</Typography>
            </Box>
            <Button 
                variant="contained" 
                disableElevation 
                fullWidth 
                size="large"
                component={'a'} 
                href="https://app.smartplan.no/auth/register"
            >
                Kom i gang
            </Button>
        </Box>
    )
}

export default BasicTile