import { Box, Button, Typography, useTheme } from '@mui/material'
import React from 'react'

function FreeTile(props) {

    const { width = '18%' } = props

    const theme = useTheme()

    return (
        <Box 
            sx={{
                height: 282,
                width: width,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                p:2,
                textAlign: 'left'
            }}
        >
            <Typography variant="subtitle1">Gratis</Typography>
            <Typography variant="body2" color="text.secondary">Kom igang med prosjektarbeidet helt gratis. Oppgrader etter behov.</Typography>
            <Box 
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="h4" color={theme.palette.success.main} sx={{mr:1, fontWeight: 500}}>kr 0,-</Typography>
                <Typography variant="body2" color="text.secondary">Gratis for alltid</Typography>
            </Box>
            <Button 
                variant="outlined" 
                fullWidth 
                size="large"
                component={'a'} 
                href="https://app.smartplan.no/auth/register"
            >
                Prøv gratis
            </Button>
        </Box>
    )
}

export default FreeTile