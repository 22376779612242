import { Box, Button, Chip, Typography, useTheme } from '@mui/material'
import React from 'react'

function PlusTile(props) {

    const { width = '18%', featured=true } = props
    const theme = useTheme()

    return (
        <>
            <Box 
                sx={{
                    position: 'relative',
                    height: 282,
                    width: width,
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    border: featured ? `1px solid #122F4C` : 'none',
                    borderBottom: 0,
                    background: featured ? '#051B33' : 'none',
                    borderRadius: '9px 9px 0 0',
                    p:2,
                    textAlign: 'left'
                }}
            >

                {featured && (
                    <Chip 
                        variant="outlined"
                        color='primary'
                        size="small"
                        label="Anbefalt"
                        sx={{
                            background: '#051B33',
                            borderColor: '#122F4C',
                            position: 'absolute',
                            top: -16,
                            right: 16,
                            fontWeight: 500
                        }}
                    />
                )}

                <Typography variant="subtitle1">Pluss</Typography>
                <Typography variant="body2" color="text.secondary">Prosjektarbeid med de fleste funksjoner aktivert. Oppgrader når behovet endres.</Typography>
                <Box 
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <Typography variant="h4" color="primary" sx={{mr:1, fontWeight: 500}}>kr 1 399,-</Typography>
                    <Typography variant="body2" color="text.secondary">pr mnd / pr prosjekt</Typography>
                </Box>
                <Button 
                    variant="contained" 
                    disableElevation 
                    fullWidth 
                    size="large"
                    component={'a'} 
                    href="https://app.smartplan.no/auth/register"
                >
                    Kom i gang
                </Button>
            </Box>
        </>
    )
}

export default PlusTile