import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import React from 'react'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import ViewDeviation from '../Home/HomeComponents/ProductViewer/ViewDeviation'
import ViewFiles from '../Home/HomeComponents/ProductViewer/ViewFiles'

function Files() {

    const theme = useTheme()
    
    return (
        <Grid container spacing={6} alignItems="center">
            <Grid item md={4} xs={12}>
                <Typography variant="h4" color="primary" gutterBottom sx={{fontWeight: 500}}>Fillagring</Typography>
                <Typography gutterBottom>
                Lagring av prosjektrelaterte filer og dokumenter.
                </Typography>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Opprett egendefinert mappestruktur for prosjektet.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Begrens tilgang til filer basert på deltakernes roller.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Systemmapper som automatisk generer filer basert på aktivitet i Smartplan.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Versjonshistorikk.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: theme.palette.primary.main}} />                            
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="text.secondary">Eksporter enkelt innhold fra prosjektet.</Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </Grid>
            <Grid item md={2} xs={0} />
            <Grid item md={6} xs={12}>
                <Box sx={{height: 400, minHeight: 100}}>
                    <ViewFiles />
                </Box>
            </Grid>
        </Grid>
    )
}

export default Files